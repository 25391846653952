import "react-dates/lib/css/_datepicker.css"
import Cleave from "cleave.js/react"
import * as React from "react"
import PhoneInput from "react-phone-number-input/input"
import { isValidPhoneNumber } from "react-phone-number-input"
import "react-dates/initialize"
import { DayPickerRangeController } from "react-dates"
import Fade from "react-reveal/Fade"

import { Popover } from "react-tiny-popover"

const SubscriptionForm = ({ className, title, transparentBg }) => {
    const [isLoading, setIsLoading] = React.useState(false)
    const [data, setData] = React.useState({})
    const [success, setSuccess] = React.useState()
    const [fail, setFail] = React.useState()
    const [focusedInput, setFocusedInput] = React.useState("startDate")
    const [popoverIsOpen, setPopoverIsOpen] = React.useState(false)
    const [errors, setErrors] = React.useState({})
    const onSubmit = () => {
        const newErrors = {}

        const { email, firstName, dateOfBirth, phoneNumber } = data
        setIsLoading(true)
        if (!firstName?.length) {
            newErrors.firstName = "Required"
        }
        if (!email?.length) {
            newErrors.email = "Required"
        } else {
            var re = /\S+@\S+\.\S+/
            if (!re.test(email)) {
                newErrors.email = "Example: name@example.com"
            }
        }
        if (!phoneNumber) {
            newErrors.phoneNumber = "Required"
        }
        if (phoneNumber && !isValidPhoneNumber(phoneNumber)) {
            newErrors.phoneNumber = "Example: +44 8870 587 125"
        }

        if (dateOfBirth && dateOfBirth.length !== 5) {
            newErrors.dateOfBirth = "Example: 27/01 for 27 January"
        }

        if (!Object.keys(newErrors).length) {
            subscribe()
            setErrors({})
        } else {
            setErrors(newErrors)
            setIsLoading(false)
        }
    }

    async function subscribe() {
        const { email, firstName, dateOfBirth, phoneNumber, startDate, endDate } = data

        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                firstName,
                email,
                phoneNumber,
                dateOfBirth,
                from: startDate,
                till: endDate
            })
        }
        try {
            const response = await fetch(
                "https://solvexus-klaviyo-production.azurewebsites.net/Subscription/action",
                requestOptions
            )
            if (response.ok) {
                setFail(null)
                setSuccess(true)
                setData({})
            } else {
                setSuccess(null)
                setFail(true)
            }
            setIsLoading(false)
        } catch (error) {
            setSuccess(null)
            setFail(true)
            setIsLoading(false)
        }
    }

    return (
        <div className={`container-fluid container-xxl p-0 ${className ?? ""}`}>
            <Fade delay={500} duration={1500}>
                <div className="row g-0">
                    <div className={`col d-flex ${transparentBg ? "" : "justify-content-center"}`}>
                        <div
                            style={{ backgroundColor: transparentBg ? "transparent" : undefined }}
                            className={`contact-form form ${transparentBg ? "transparent" : undefined}`}
                        >
                            {title}
                            <div className="row g-3  my-3">
                                <div className="col-md">
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className={"form-control" + (errors.firstName ? " error" : "")}
                                            id="firstName"
                                            placeholder="First Name"
                                            value={data.firstName ?? ""}
                                            onChange={e => setData(d => ({ ...d, firstName: e.target.value }))}
                                        />
                                        <label htmlFor="firstName">First Name</label>
                                        {errors.firstName && <span className="form-error-message">{errors.firstName}</span>}
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div className="form-floating">
                                        <input
                                            type="email"
                                            className={"form-control" + (errors.email ? " error" : "")}
                                            id="email"
                                            placeholder="name@example.com"
                                            value={data.email ?? ""}
                                            onChange={e => setData(d => ({ ...d, email: e.target.value }))}
                                        />
                                        <label htmlFor="email">Email address</label>
                                        {errors.email && <span className="form-error-message">{errors.email}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row  g-3  mb-3">
                                <div className="col-md">
                                    <div className="form-floating">
                                        <Cleave
                                            options={{ date: true, datePattern: ["d", "m"] }}
                                            value={data.dateOfBirth ?? ""}
                                            onChange={e => setData(d => ({ ...d, dateOfBirth: e.target.value }))}
                                            type="text"
                                            className={"form-control" + (errors.dateOfBirth ? " error" : "")}
                                            id="dateOfBirth"
                                            placeholder="name@example.com"
                                        />
                                        <label htmlFor="dateOfBirth">Date of Birth (DD/MM)</label>
                                        {errors.dateOfBirth && <span className="form-error-message">{errors.dateOfBirth}</span>}
                                    </div>
                                </div>
                                <div className="col-md">
                                    <div className="form-floating">
                                        <PhoneInput
                                            value={data.phoneNumber || ""}
                                            onChange={e => setData(d => ({ ...d, phoneNumber: e }))}
                                            type="text"
                                            placeholder="Phone number"
                                            className={"form-control" + (errors.phoneNumber ? " error" : "")}
                                            id="phoneNumber"
                                        />
                                        <label htmlFor="phoneNumber">Phone Number</label>
                                        {errors.phoneNumber && <span className="form-error-message">{errors.phoneNumber}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row g-3  mb-3">
                                <div className="col-12 col-md-6">
                                    <div className="form-floating">
                                        <Popover
                                            isOpen={popoverIsOpen}
                                            position="bottom"
                                            padding={10}
                                            reposition={false}
                                            onClickOutside={() => setPopoverIsOpen(false)} // handle click events outside of the popover/target here!
                                            content={(
                                                { position, nudgedLeft, nudgedTop } // you can also provide a render function that injects some useful stuff!
                                            ) => (
                                                <DayPickerRangeController
                                                    hideKeyboardShortcutsPanel
                                                    startDate={data.startDate}
                                                    startDateId="start_date_id"
                                                    endDate={data.endDate}
                                                    endDateId="end_date_id"
                                                    onDatesChange={({ startDate, endDate }) =>
                                                        setData(d => ({ ...d, startDate, endDate }))
                                                    }
                                                    focusedInput={focusedInput}
                                                    onFocusChange={focusedInput => setFocusedInput(focusedInput || "startDate")}
                                                />
                                            )}
                                        >
                                            <input
                                                onClick={() => {
                                                    setPopoverIsOpen(!popoverIsOpen)
                                                }}
                                                onChange={() => setData(d => ({ ...d, startDate: undefined, endDate: undefined }))}
                                                value={
                                                    data.startDate || data.endDate
                                                        ? (data.startDate?.format("MMM Do") ?? " ") +
                                                          " - " +
                                                          (data.endDate?.format("MMM Do") ?? " ")
                                                        : ""
                                                }
                                                type="text"
                                                className={"form-control" + (errors.dates ? " error" : "")}
                                                id="date"
                                                placeholder="name@example.com"
                                            />
                                        </Popover>
                                        <label htmlFor="date">When will you be in Ibiza?</label>
                                        {errors.dates && <span className="form-error-message">{errors.dates}</span>}
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    {success ? <h5 className="text-success">You are subscribed, thank you!</h5> : null}
                                    {fail ? (
                                        <h6 className="text-danger">
                                            <>
                                                Could not subscribe. Please{" "}
                                                <a className="text-white" href="mailto:chartering@malibueyachts.com.">
                                                    email
                                                </a>{" "}
                                                us.
                                            </>
                                        </h6>
                                    ) : null}
                                    {!success && !fail ? (
                                        <p className={`text-white disclaimer ${transparentBg ? "news-letter-form-style" : ""}`}>
                                            By clicking Subscribe, you agree to receive marketing text messages and Emails from
                                            Maliblue by Candypants at the number and e-mail address provided. You can unsubscribe at
                                            anytime.
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <button
                                        className={`button fill ${transparentBg ? "light-background" : "dark"}`}
                                        disabled={isLoading}
                                        onClick={onSubmit}
                                    >
                                        <span>subscribe</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </div>
    )
}

export default SubscriptionForm
